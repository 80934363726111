/* 
    stretch so all columns start at top 
    https://teamtreehouse.com/community/flexbox-make-all-flexitems-the-same-heights
  */
.skillCards {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.skillCardSection {
  text-align: center;
  width: var(--width-column);
}

.skillCardTitle {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 30px;
  line-height: 113%;
  color: var(--color-primary);
  margin: 1.25rem 0;
}

.skillCardKeywords {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  gap: 1.125rem;

  font-size: 28px;
  line-height: 113%;
}

/* takes into account Galaxy Fold 280px width */
@media only screen and (max-width: 300px) {
  .skillCardSection {
    width: var(--width-column-narrow);
  }
}
