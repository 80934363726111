/* Note: stuff not 100% wide on Galaxy Fold b/c of the fold margin */
.navHeader {
  width: 100%;
  /* height accomodates MacBook Air camera location - per Figma prototype */
  height: 6rem;
  background-color: var(--color-light);

  /* sticky per https://github.com/twbs/bootstrap/blob/v5.2.3/dist/css/bootstrap.css */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
  margin-bottom: 3rem;

  box-shadow: 0 5px 20px -10px var(--color-dark);
}

.brandName {
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 42px;

  /* color-primary */
  color: var(--color-primary);
  text-decoration: none;
}

.brandName:hover {
  cursor: pointer;
}

.logoImage {
  width: 60px;
  height: 60px;
  margin-right: 0.5rem;
}

.branding {
  display: flex;
  align-items: center;
}
.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--width-standard);
  margin: 0 auto;
  height: inherit;
}

.navMenu {
  display: flex;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 28px;
  margin-left: auto;
}

.navItem:hover {
  color: var(--color-primary);
  cursor: pointer;
}

/* .navItem > .active {
  border-bottom: 2px solid var(--color-primary);
} */

.navMenuLink {
  text-decoration: none;
  color: inherit;
}

.hamburger {
  display: none;
  z-index: 99999;
}

.hamburgerBar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--color-dark);
}

@media only screen and (max-width: 972px) {
  .navMenu {
    position: fixed;
    left: -100%;
    top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    background-color: var(--color-light);
    height: 100vh;
    width: 100%;

    transition: 0.3s;
  }

  .navMenu.expanded {
    left: 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.expanded .hamburgerBar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.expanded .hamburgerBar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.expanded .hamburgerBar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}

@media only screen and (max-width: 450px) {
  .brandName {
    font-size: 1.75rem;
  }
}
