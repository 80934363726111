.resumeProjectItem,
.githubLink {
  margin-bottom: 1rem;
}

.resumeProjectItem > * {
  margin-bottom: 0.5rem;
}

.resumeProjectHeader {
  display: grid;
  grid-template-columns: inherit;
  grid-template-areas: "project-title . project-date";
  /* set row and col gaps separately */
  column-gap: inherit;
  row-gap: inherit;
}
.resumeProjectTitle {
  font-weight: 700;
  text-transform: uppercase;
  grid-area: project-title;
}

.resumeProjectDate {
  grid-area: project-date;
  justify-self: end;
}

.resumeProjectLinkTitle {
  font-weight: 700;
}

.resumeProjectLinks {
  list-style: none;
  padding-left: 1rem;
  display: flex;
  gap: 1rem;
}
