.aboutContent {
  display: flex;
  gap: 1rem;
}

.spotlight {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  width: 33%;
  margin: 50px 0 0 0;
}

.einsteinImgHolder {
  width: 161px;
  height: 174px;
}

.einsteinImgHolder > img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .aboutContent {
    flex-direction: column-reverse;
  }

  .spotlight {
    width: 100%;
  }

  .einsteinImgHolder {
    width: 82px;
    height: 86px;
  }
}

@media only screen and (max-width: 700px) {
  .aboutContent {
    width: 100%;
    gap: 0.5rem;
  }
}

/* @media only screen and (max-width: 700px) {
  .spotlight
} */
