.projectCardsContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--width-column-narrow), var(--width-column))
  );
  grid-auto-rows: auto;
  gap: 1.5rem;
}

.projectCard {
  background-color: var(--color-light);
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: solid 1px var(--color-primary);
  /* min-width: var(--width-column); */
}

.projectCardImgHolder {
  width: 100%;
  max-width: 100%;
  min-height: 270px;
  height: 270px;
  background-size: cover;
  background-position: center center;
  border-bottom: solid 2px var(--color-primary);
  margin-bottom: 2rem;
}

.projectCardBody {
  flex-grow: 1;
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
}
.projectCardTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 117%;
  width: 100%;
  height: 60px;
  text-align: center;
  margin-bottom: 1.5rem;
}

.projectCardText {
  flex-grow: 1;
  font-size: 18px;
  line-height: 167%;
}

.projectCardFooter {
  font-size: 20px;
  line-height: 150%;
  color: var(--color-primary);
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* padding: 0 20px 20px 20px; */
  padding: 40px 20px 20px 20px;

  margin-top: auto;
}

.projectCardFooter a {
  color: inherit;
}

.projectCardFooter a:hover {
  color: var(--color-dark);
}

.projectCardLink {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.projectCardIcon {
  font-size: 1.75rem;
}

/* this keeps it max 3 cols on larger screens & stays w/i margins with rest of screen sizes */
@media only screen and (min-width: 1280px) {
  .projectCardsContainer {
    grid-template-columns: repeat(3, var(--width-column));
  }
}

/* takes into account Galaxy Fold 280px width */
@media only screen and (max-width: 300px) {
  .projectCard {
    width: var(--width-column-narrow);
  }
}
