.container {
  width: var(--width-standard);
  margin: 0 auto;
}

.homeSection {
  margin-bottom: 3rem;
  scroll-margin: 9rem;
}

.sectionTitle {
  text-align: center;
  color: var(--color-primary);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 1.125rem;
}
