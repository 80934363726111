/* BREAKPOINTS - unfortunately, can't use CSS properties/variables in media queries yet.
  
  Using Tailwind breakpoints as a starting point: https://tailwindcss.com/docs/responsive-design

  Phone:
    Small - 640px
  Tablet:
    Medium - 768px
    Large - 1024px
  Desktop/laptop (MacBook Air and larger)
    X-Large - 1280px

*/

:root {
  --color-primary: #e2007a;
  --color-dark: #080908;
  --color-light: #ffffff;

  /* check this, cards are ending up wider than anticipated on 1280 screen */
  --width-standard: 90%;

  --width-column: 323px;

  --width-column-narrow: 265px;
}

* {
  box-sizing: border-box;
}

/* WOFF2 version downloaded from https://gwfh.mranftl.com/fonts/roboto?subsets=latin */
/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  /* src: url("./assets/fonts/roboto/roboto-v30-latin-regular.woff2")
    format("woff2");  */
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  src: url("./assets/fonts/roboto/roboto-v30-latin-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/roboto/roboto-v30-latin-italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/roboto/roboto-v30-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

body {
  font-family: "Roboto";
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: var(--color-dark);
}

/* unset the font-size so have to explicitly set */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  font-size: unset;
}

ul {
  list-style: none;
}

/* reset for forms */
/* https://developer.mozilla.org/en-US/docs/Learn/Forms/Styling_web_forms */
form,
button,
label,
input,
select,
textarea,
figure {
  font-family: inherit;
  font-size: 100%;
  margin-bottom: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
