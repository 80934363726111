.quoteContainer {
  color: var(--color-primary);
  width: 100%;
}

.quoteText {
  display: block;
  font-style: italic;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
  text-align: center;
}

.quoteContainer figcaption {
  font-size: 1rem;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quoteContainer figcaption > cite {
  margin-left: 20px;
}
.quoteContainer a {
  color: inherit;
}

.quoteContainer a:hover {
  color: var(--color-dark);
}

@media only screen and (max-width: 700px) {
  .quoteText {
    font-size: 1.125rem;
  }

  .quoteContainer figcaption {
    flex-direction: column;
  }
}
