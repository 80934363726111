.contactInfo {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-template-rows: 0.5fr 0.5fr;
  gap: 1.75rem;
}

/* https://stackoverflow.com/questions/44401674/how-to-create-a-css-grid-layout-box-that-spans-2-columns-and-2-rows */
.contactLink:first-child {
  grid-column: 1 / span 2;

  /* grid align stuff not work */
  margin: 0 auto;
}

.contactLink {
  font-size: 1.375rem;
  line-height: 127%;
  color: inherit;
}

.contactItem {
  display: flex;
  align-items: center;

  gap: 1.5rem;
}

@media only screen and (max-width: 1280px) {
  .contactItem {
    gap: 1rem;
  }
  .contactLink {
    font-size: 1.2rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* this actually causes it to wrap */
    word-break: break-all;
  }
}

@media only screen and (max-width: 972px) {
  .contactInfo {
    grid-template-columns: 1fr;
  }
}
