/* should i be using margin-left not padding? */
.heroContent {
  display: flex;
  flex-direction: column;

  /* need this so intro goes no wider than actual text */
  align-items: flex-start;

  margin-left: 75px;
  width: 65%;
}

.heroLink {
  color: var(--color-primary);
  text-decoration: underline;
}

.heroLink:hover {
  cursor: pointer;
}

/* animation per https://blog.hubspot.com/website/typing-animation-css */

.introContainer {
  display: inline-block;
  font-weight: 700;
  font-size: 2.25rem;
  margin-bottom: 2rem;
}
.intro {
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid;
  width: 0;
  animation: typing 1.5s steps(30, end) forwards, blinking 1s infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blinking {
  0% {
    border-right-color: transparent;
  }
  50% {
    border-right-color: var(--color-dark);
  }
  100% {
    border-right-color: transparent;
  }
}

.summary {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 121%;
  margin-left: 50px;
  max-width: 100%;
}

.summary > p {
  margin-bottom: 1rem;
}

.heroContent > a {
  align-self: center;
}

.cta {
  font-size: 1.125rem;
  margin-left: 125px;
}
.ctaHeader {
  color: var(--color-primary);
  font-size: 1.375rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 700px) {
  .heroContent {
    width: 100%;
    margin: 0;
    align-items: center;
    gap: 25px;
    text-align: center;
  }
  .intro {
    font-size: 1.625rem;
  }

  .summary {
    margin: 0;
    font-size: 1.25rem;
  }

  .cta {
    margin-left: 0;
  }
}
