.resumeSkillList {
  list-style: inside;
  padding-left: 1rem;
}

.resumeSkillTitle {
  margin-right: 0.5rem;
  display: inline;
  text-transform: uppercase;
  font-weight: 700;
}

/* either inline-flex or need to add inline to both the ul & li elements */
.resumeKeywords {
  display: inline-flex;
}

.resumeKeywords > li {
  margin: 0;
  padding: 0 0.25rem;
  border-right: 1px solid;
}

.resumeKeywords > li:last-child {
  border-right: none;
}

@media only screen and (max-width: 700px) {
  .resumeSkillList {
    font-size: 0.75rem;
  }
}
/* NOTE: can only do so much on extra small screens like Galaxy Fold.  */
@media only screen and (max-width: 400px) {
  .resumeKeywords {
    display: flex;
    flex-direction: column;
  }

  .resumeKeywords > li {
    border: none;
    text-align: center;
  }
}
