.contactContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5%;
  /* min-height: 650px;
  height: 650px; */
}

/* .contactContent,
.contactFormHolder {
  flex: 1 0 45%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  padding: 0 1rem;
} */

/* style w/o contact form */
.contactContent {
  margin: 0 auto;
  width: 60%;
  /* min-height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  padding: 0 1rem;
}

.contactTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  text-align: center;
  color: var(--color-primary);
  margin-bottom: 1rem;
}

.contactCTA {
  font-size: 1.625rem;
  line-height: 30px;
  margin-bottom: 1.25rem;
}

@media only screen and (max-width: 1280px) {
  .contactCTA {
    font-size: 1.25rem;
  }

  .contactContent {
    margin-bottom: 1.5rem;
  }
}
